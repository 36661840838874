<template>
    <header class="page-title">
        <h1><i class="bi bi-people"></i> 案件新規登録</h1>
        <router-link :to="{name: 'Home'}" class="btn btn-outline-primary"><i class="bi bi-reply"></i> 案件一覧へ</router-link>
    </header>

    <inline-loader v-if="loading"></inline-loader>
    <template v-else>
        <form @submit.prevent="create()">
        <section class="section">
            <header class="section-title">
                <h2>顧客情報</h2>
            </header>
            <div class="mb-3">
                <div class="btn-group">
                    <div class="btn" :class="is_new ? 'btn-primary' : 'btn-outline-primary'" @click="switchNew()">新規顧客</div>
                    <div class="btn" :class="is_new ? 'btn-outline-primary' : 'btn-primary'" @click="switchExist()">既存顧客</div>
                </div>
            </div>
            <!-- 新規顧客の場合 -->
            <div v-if="is_new === true">
                <div class="row mb-3 align-items-end">
                    <div class="col-6">
                        <label class="form-label">会社名 <i class="required"></i></label>
                        <form-input v-model="project.customer.company_name" :required="true"></form-input>
                    </div>
                    <div class="col-6">
                        <label class="form-label">代表者名</label>
                        <form-input v-model="project.customer.representative_name"></form-input>
                    </div>
                    <div class="col-12">
                        <label class="form-label">本社住所</label>
                        <form-input v-model="project.customer.address"></form-input>
                    </div>
                    <div class="col-6">
                        <label class="form-label">業種</label>
                        <form-select-search
                            v-model="project.customer.industry"
                            :options="Industry.options()"
                            empty_option="----"
                        ></form-select-search>
                    </div>
                    <div class="col-6">
                        <form-checkbox
                            v-model="project.customer.is_major"
                            option_label="大手企業"
                        ></form-checkbox>
                    </div>
                </div>
                <div class="row mb-3 align-items-end">
                    <div class="col-6">
                        <label class="form-label">会社規模</label>
                        <form-select
                            v-model="project.customer.company_scale"
                            :options="CompanyScale.options()"
                            empty_option="----"
                        ></form-select>
                    </div>
                    <div class="col-6">
                        <label class="form-label">従業員数</label>
                        <form-input-number v-model="project.customer.employee_number" min="0" max="99999999"></form-input-number>
                    </div>
                    <div class="col-6">
                        <label class="form-label">Webサイト</label>
                        <form-input v-model="project.customer.website"></form-input>
                    </div>
                    <div class="col-6">
                        <label class="form-label">電話</label>
                        <form-input v-model="project.customer.phone"></form-input>
                    </div>
                    <div class="col-6">
                        <label class="form-label">Fax</label>
                        <form-input v-model="project.customer.fax"></form-input>
                    </div>
                    <div class="col-6">
                        <label class="form-label">決算期</label>
                        <div class="input-group">
                            <form-input-number
                                v-model="project.customer.fiscal_month"
                                min="1"
                                max="12"
                            ></form-input-number>
                            <span class="input-group-text">月</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 既存顧客 -->
            <div v-else>
                <div class="row align-items-end mb-4">
                    <div class="col-9">
                        <label class="form-label">会社名</label>
                        <form-input v-model="search_company_name"></form-input>
                    </div>
                    <div class="col-9">
                        <button type="button" @click="searchCustomer()" class="btn btn-outline-primary"><i class="bi bi-search"></i> 検索</button>
                    </div>
                </div>
                <template v-if="customers_searched">
                    <template v-if="customers.length">
                        <p class="mb-3">該当 : {{ customers_total }} 件 <span v-if="customers_total > customers_to">(最初の {{ customers_to }} 件を表示しています)</span></p>
                        <table class="table table-sm" style="width: 80%">
                            <thead>
                                <tr class="table-dark">
                                    <th class="text-center col-4">会社名</th>
                                    <th class="text-center col-7">本社住所</th>
                                    <th class="text-center col-3">電話</th>
                                    <th class="text-center col-1"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="customer in customers"
                                    :key="customer.customer_id"
                                    :class="selected_customer_id === customer.customer_id ? 'bg-light' : ''"
                                >
                                    <td class="align-middle">{{ customer.company_name }}</td>
                                    <td class="align-middle">{{ customer.address }}</td>
                                    <td class="align-middle">{{ customer.phone }}</td>
                                    <td class="align-middle">
                                        <button type="button" class="btn btn-outline-primary" @click="customerSelected(customer.customer_id)">
                                            {{ selected_customer_id === customer.customer_id ? '解除' : '選択' }}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-else>
                        <p class="alert alert-secondary">※該当の顧客が存在しません</p>
                    </template>
                </template>
            </div>
        </section>

        <section class="section">
            <header class="section-title">
                <h2>案件情報</h2>
            </header>

            <h3 class="mb-2 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 基本情報</h3>
            <div class="row mb-3 align-items-end">
                <div class="col-6">
                    <label class="form-label">事業部 <i class="required"></i></label>
                    <form-select-object
                        v-model="project.department"
                        :options="options_department"
                        :required="true"
                        option_value="department_id"
                        option_label="department_name"
                        empty_option="----"
                        @selected="fetchOptions()"
                    ></form-select-object>
                </div>
                <div class="col-6">
                    <label class="form-label">ステータス <i class="required"></i></label>
                    <form-select
                        v-model="project.status"
                        :options="Status.options()"
                        empty_option="----"
                        :required="true"
                    ></form-select>
                </div>
                <div class="col-6">
                    <label class="form-label">反響対応者</label>
                    <form-select-search
                        v-model="project.contact_user.user_id"
                        :options="options_user"
                        placeholder="----"
                    ></form-select-search>
                </div>
                <div class="col-6">
                    <label class="form-label">追客担当</label>
                    <form-select-search
                        v-model="project.follow_user.user_id"
                        :options="options_user"
                        placeholder="----"
                    ></form-select-search>
                </div>
                <div class="col-6">
                    <label class="form-label">営業担当</label>
                    <form-select-search
                        v-model="project.sales_user.user_id"
                        :options="options_user"
                        placeholder="----"
                    ></form-select-search>
                </div>
                <div class="col-6">
                    <label class="form-label">優先度</label>
                    <form-select-object
                        v-model="project.probability"
                        :options="options_probability"
                        option_value="probability_id"
                        option_label="probability_name"
                        empty_option="----"
                        :disabled="is_disabled > 0"
                    ></form-select-object>
                </div>
            </div>
            <div class="row mb-3 align-items-end">
                <div class="col-6">
                    <label class="form-label">評価</label>
                    <form-select
                        v-model="project.rank"
                        :options="Rank.options()"
                        empty_option="----"
                    ></form-select>
                </div>
                <div class="col-6">
                    <label class="form-label">サブ評価</label>
                    <form-select-object
                        v-model="project.detailed_rank"
                        :options="options_detailed_rank"
                        option_value="detailed_rank_id"
                        option_label="detailed_rank_name"
                        empty_option="----"
                        :disabled="is_disabled > 0"
                    ></form-select-object>
                </div>
                <div class="col-6">
                    <label class="form-label">確実度</label>
                    <form-select-object
                        v-model="project.certainty"
                        :options="options_certainty"
                        option_value="certainty_id"
                        option_label="certainty_name"
                        empty_option="----"
                        :disabled="is_disabled > 0"
                    ></form-select-object>
                </div>
                <div class="col-6">
                    <label class="form-label">計測カウント</label>
                    <form-select
                        v-model="project.count_type"
                        :options="CountType.options()"
                        empty_option="----"
                    ></form-select>
                </div>
            </div>

            <h3 class="mb-2 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 規格情報</h3>
            <div class="row mb-3">
                <div class="col-12 mb-3">
                    <label class="form-label">規格</label>
                    <form-select-search-multiple
                        v-model="project.standards_values"
                        :options="Standard.options()"
                    ></form-select-search-multiple>
                </div>
                <div class="col-12">
                    <label class="form-label">その他規格・備考</label>
                    <form-input v-model="project.standard_note"></form-input>
                </div>
                <div class="col-6">
                    <label class="form-label">認証有効期限</label>
                    <form-input-date
                        v-model="project.certification_expiry_date"
                    ></form-input-date>
                </div>
            </div>

            <h3 class="mb-2 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 反響情報</h3>
            <div class="row mb-3">
                <div class="col-6">
                    <label class="form-label">反響日</label>
                    <form-input-date
                        v-model="project.contact_date"
                        :required="true"
                    ></form-input-date>
                </div>
                <div class="col-6">
                    <label class="form-label">紹介元（コンサル以外）</label>
                    <form-input
                        v-model="project.referrer"
                    ></form-input>
                </div>
                <div class="col-6">
                    <label class="form-label">チャネル <i class="required"></i></label>
                    <form-select-object-search
                        v-model="project.channel"
                        :options="options_channel"
                        option_value="channel_id"
                        option_label="channel_name"
                        :required="true"
                    ></form-select-object-search>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <label class="form-label">反響備考</label>
                    <form-textarea
                        v-model="project.contact_note"
                    ></form-textarea>
                </div>
            </div>

            <h3 class="mb-2 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> コンサル紹介情報</h3>
            <div class="row mb-3">
                <div class="col-6">
                    <label class="form-label">紹介元（お客様名）</label>
                    <form-input v-model="project.referrer_company"></form-input>
                </div>
                <div class="col-6">
                    <label class="form-label">担当コンサル</label>
                    <form-select-object-search
                        v-model="project.referrer_company_consultant_user"
                        :options="options_referrer_company_consultant_user"
                        option_value="user_id"
                        option_label="user_name"
                    ></form-select-object-search>
                </div>
            </div>

            <h3 class="mb-2 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 客先担当者</h3>
            <div class="row align-items-end mb-5">
                <div class="col-6">
                    <label class="form-label">氏名 <i class="required"></i></label>
                    <form-input v-model="project_contact.contact_name" :required="true"></form-input>
                </div>
                <div class="col-6">
                    <label class="form-label">フリガナ</label>
                    <form-input v-model="project_contact.contact_name_kana"></form-input>
                </div>
                <div class="col-6">
                    <label class="form-label">役職</label>
                    <div class="input-group">
                        <form-checkbox v-model="project_contact.is_ceo" option_label="代表" style="white-space: nowrap;"></form-checkbox>
                        <form-input v-model="project_contact.position" style="flex-grow: 2;"></form-input>
                    </div>
                </div>
                <div class="col-6">
                    <label class="form-label">部署</label>
                    <form-input v-model="project_contact.division"></form-input>
                </div>
                <div class="col-6">
                    <label class="form-label">携帯</label>
                    <form-input v-model="project_contact.phone"></form-input>
                </div>
                <div class="col-6">
                    <label class="form-label">メール</label>
                    <form-input v-model="project_contact.email"></form-input>
                </div>
            </div>

            <h3 class="mb-2 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 住所</h3>
            <div class="row mb-3">
                <div class="col-6">
                    <label class="form-label">郵便番号</label>
                    <div class="input-group">
                        <span class="input-group-text">〒</span>
                        <form-input v-model="project.zip_code"></form-input>
                    </div>
                </div>
                <div class="col-6">
                    <label class="form-label">都道府県</label>
                    <form-select-object-search
                        v-model="project.area"
                        :options="options_area"
                        option_value="area_id"
                        option_label="area_name"
                    ></form-select-object-search>
                </div>
                <div class="col-6">
                    <label class="form-label">市区郡</label>
                    <form-input v-model="project.city"></form-input>
                </div>
                <div class="col-6">
                    <label class="form-label">町名・番地</label>
                    <form-input v-model="project.street"></form-input>
                </div>
                <div class="col-6">
                    <label class="form-label">Google Maps</label>
                    <form-input v-model="project.google_map"></form-input>
                </div>
                <div class="col-6">
                    <label class="form-label">最寄駅</label>
                    <form-input v-model="project.closest_station"></form-input>
                </div>
            </div>
        </section>

        <section class="section">
            <button type="submit" class="btn btn-lg btn-info">登録</button>
        </section>

        </form>
    </template>

</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Project from '@/models/entities/project';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormSelectObject from '@/components/forms/FormSelectObject';
import FormSelectObjectSearch from '@/components/forms/FormSelectObjectSearch';
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';
import FormInputDate from '@/components/forms/FormInputDate';
import FormTextarea from '@/components/forms/FormTextarea';
import FormCheckbox from '@/components/forms/FormCheckbox';
import ProjectContact from '@/models/entities/project-contact';
import Department from '@/models/entities/department';
import User from '@/models/entities/user';
import Probability from '@/models/entities/probability';
import DetailedRank from '@/models/entities/detailed-rank';
import Certainty from '@/models/entities/certainty';
import Channel from '@/models/entities/channel';
import Area from '@/models/entities/area';
import Customer from '@/models/entities/customer';
import Industry from '@/models/enums/industry';
import CompanyScale from '@/models/enums/company-scale';
import Status from '@/models/enums/status';
import Rank from '@/models/enums/rank';
import CountType from '@/models/enums/count-type';
import Standard from '@/models/enums/standard';

export default {
    name: 'ProjectAdd',
    components: {
        InlineLoader,
        FormInput,
        FormSelect,
        FormInputNumber,
        FormSelectObject,
        FormSelectObjectSearch,
        FormSelectSearch,
        FormSelectSearchMultiple,
        FormInputDate,
        FormTextarea,
        FormCheckbox,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // 新規顧客/既存顧客切り替えフラグ
            is_new: false,
            // 既存顧客 選択されているcustomer_id
            selected_customer_id: null,
            // ローディング
            loading: false,
            // 事業部選択時 優先度、確実度、サブ評価disabledにするフラグ
            is_disabled: 0,

            // 既存顧客検索用 会社名
            search_company_name: null,
            // 既存会社一覧
            customers_searched: false,
            customers: [],
            customers_to: null,
            customers_total: null,

            // option一覧
            Industry,
            CompanyScale,
            Status,
            Rank,
            CountType,
            Standard,
            options_department: [],
            options_user: [],
            options_probability: [],
            options_detailed_rank: [],
            options_certainty: [],
            options_channel: [],
            options_area: [],
            options_referrer_company_consultant_user: [],

            // 新規登録 案件
            project: new Project(),

            // 客先担当者
            project_contact: new ProjectContact(),
        }
    },
    mounted() {
        this.fetchAllOptions();
        this.project.contact_date = this.$helper.ymdForInputDate(new Date()); // 反響日デフォルトで今日の日付をセット
    },
    methods: {
        // optionのデータ取得
        async fetchAllOptions() {
            this.loading = true;

            // 事業部、ユーザ、チャネル、エリアoption取得
            await Promise.all([
                this.fetchDepartmentOptions(),
                this.fetchUserOptions(),
                this.fetchChannelOptions(),
                this.fetchAreaOptions(),
            ])

            this.loading = false;
        },
        // 事業マスタ取得
        fetchDepartmentOptions() {
            return new Promise(resolve => {
                this.$http.get('/master/department')
                .then(response => {
                    for (let row of response.data) {
                        let department = new Department(row);
                        this.options_department.push(department);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // ユーザマスタ取得
        fetchUserOptions() {
            return new Promise(resolve => {
                this.$http.get('/user')
                .then(response => {
                    for (let row of response.data) {
                        let user = new User(row);
                        this.options_user.push({value: user.user_id, label: user.user_name});
                        this.options_referrer_company_consultant_user.push(user);
                    }

                    // form-select-searchなぜかnullにしておかないとundefinedでは初期にoptionが入っていない
                    this.project.contact_user.user_id = null;
                    this.project.follow_user.user_id = null;
                    this.project.sales_user.user_id = null;
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // チャネルマスタ取得
        fetchChannelOptions() {
            return new Promise(resolve => {
                this.$http.get('/master/channel')
                .then(response => {
                    for (let row of response.data) {
                        let channel = new Channel(row);
                        this.options_channel.push(channel);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // エリアマスタ取得
        fetchAreaOptions() {
            return new Promise(resolve => {
                this.$http.get('/master/area')
                .then(response => {
                    for (let row of response.data) {
                        let area = new Area(row);
                        this.options_area.push(area);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },

        // 事業部選択されたら優先度、サブ評価、確実度マスタ取得
        fetchOptions() {
            if (this.$helper.isBlank(this.project.department)) {
                // option, 値リセット
                this.options_probability.splice(0);
                this.options_detailed_rank.splice(0);
                this.options_certainty.splice(0);
                this.project.probability = null;
                this.project.detailed_rank = null;
                this.project.certainty = null;
                return;
            }
            this.fetchProbabilityOptions();
            this.fetchDetailedRankOptions();
            this.fetchCertaintyOptions();
        },
        // 優先度マスタ取得
        fetchProbabilityOptions() {
            this.is_disabled++;

            // 選択されている優先度をリセット
            this.project.probability = new Probability();

            this.options_probability.splice(0);

            this.$http.get('/master/probability', {
                params: {
                    department: this.project.department.department_id,
                }
            })
            .then(response => {
                for (let row of response.data) {
                    let probability = new Probability(row);
                    this.options_probability.push(probability);
                }
            })
            .finally(() => {
                this.is_disabled--;
            })
        },
        // サブ評価マスタ取得
        fetchDetailedRankOptions() {
            this.is_disabled++;

            // 選択されているサブ評価をリセット
            this.project.detailed_rank = new DetailedRank();

            this.options_detailed_rank.splice(0);

            this.$http.get('/master/detailed-rank', {
                params: {
                    department: this.project.department.department_id,
                }
            })
            .then(response => {
                for (let row of response.data) {
                    let detailed_rank = new DetailedRank(row);
                    this.options_detailed_rank.push(detailed_rank);
                }
            })
            .finally(() => {
                this.is_disabled--;
            })
        },
        // 確実度マスタ取得
        fetchCertaintyOptions() {
            this.is_disabled++;

            // 選択されている確実度をリセット
            this.project.certainty = new Certainty();

            this.options_certainty.splice(0);

            this.$http.get('/master/certainty', {
                params: {
                    department: this.project.department.department_id,
                }
            })
            .then(response => {
                for (let row of response.data) {
                    let certainty = new Certainty(row);
                    this.options_certainty.push(certainty);
                }
            })
            .finally(() => {
                this.is_disabled--;
            })
        },

        // 追加処理
        create() {
            // 既存顧客の場合顧客が選択されていなければエラー
            if (!this.is_new) {
                if (this.$helper.isBlank(this.project.customer.customer_id)) {
                    this.showErrorMessage('顧客を選択してください');
                    return;
                }
            }

            this.startScreenLoading();

            // 客先担当者を配列にセット 1個だけど配列で送る
            this.project.contacts.splice(0);
            this.project.contacts.push(this.project_contact);

            this.$http.post('/project', this.project)
            .then((response) => {
                this.showMessage('追加しました');
                const project = new Project(response.data);
                this.$router.push({name: 'ProjectEdit', params: {id: project.project_id}});
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },

        // 既存顧客選択処理
        // 選択されたら案件に紐づく顧客にcustomer_idをセット
        // customer_idがあるかないかで新規顧客or既存顧客判定
        customerSelected(customer_id) {
            // 解除ボタン時
            if (this.selected_customer_id === customer_id) {
                this.selected_customer_id = null;
                return;
            }

            // 選択ボタン時
            this.selected_customer_id = customer_id;
            this.project.customer.customer_id = customer_id;
        },

        // 新規顧客/既存顧客切り替え
        switchNew() {
            this.is_new = true;
            this.selected_customer_id = null;
            // セットされているcustomer_idをリセット
            this.project.customer.customer_id = null;
        },
        switchExist() {
            this.is_new = false;
            this.selected_customer_id = null;
        },
        // 既存顧客検索
        searchCustomer() {
            if (this.$helper.isBlank(this.search_company_name)) {
                return;
            }
            this.startScreenLoading();

            this.customers.splice(0);

            this.$http.get('/customer', {
                params: {
                    company_name: this.search_company_name,
                }
            })
            .then(response => {
                for (let row of response.data.data) {
                    this.customers.push(new Customer(row));
                }
                this.customers_searched = true;
                this.customers_total = response.data.meta.total;
                this.customers_to = response.data.meta.to;
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
